<template>
  <div class="article">
    <transition name="slide-fade-1">
      <div class="receiving tbl_wrap" key="tbl" v-if="selectedIndex == -1">
        <div class="tbl_option">
          <h5>제품기준 출고처리</h5>
        </div>
        <h6>조회수 : {{ sales_product_list.length }}건</h6>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <colgroup>
              <col v-for="(n, index) in 8" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>제품명</th>
                <th>규격</th>
                <th>납품처</th>
                <th>수량(단위)</th>
                <th>단가</th>
                <th>매출액</th>
                <th>수주일자</th>
                <th>납품요청일</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in sales_product_list"
                :key="item.id"
                @click="selectRow(index)"
                :class="{ active: selectedIndex == index }"
              >
                <td class="text_left">
                  {{ findInfoFromId(products, item.product_id).name }}
                </td>
                <td class="text_left">
                  {{ findInfoFromId(products, item.product_id).standard }}
                </td>
                <td class="text_left">
                  {{
                    findInfoFromId(
                      companys,
                      findInfoFromId(sales, item.sales_id).company_id,
                    ).name
                  }}
                </td>
                <td
                  class="text_right"
                  :class="{ box_name_active: item.box_unit_id != null }"
                >
                  <b>{{ `${$makeComma(item.quantity)}` }}</b>
                  <span>
                    {{
                      `${
                        item.box_unit_id != null
                          ? findInfoFromId(boxs, item.box_unit_id).name
                          : findInfoFromId(units, item.unit_id).name
                      }`
                    }}
                  </span>
                </td>
                <td class="text_right">&#8361; {{ item.cost | makeComma }}</td>
                <td class="text_right">
                  &#8361; {{ (item.total_cost - item.discount) | makeComma }}
                </td>
                <td>
                  {{
                    findInfoFromId(sales, item.sales_id).create_time
                      | formatDateNoHours
                  }}
                </td>
                <td>
                  {{
                    findInfoFromId(sales, item.sales_id).delivery_date
                      | formatDateNoHours
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="aside_management_mode" key="aside" v-if="selectedIndex != -1">
        <div class="head">
          <button type="button" class="btn_prev" @click="selectRow(-1)">
            <h5>제품 현황</h5>
          </button>
        </div>
        <div class="form">
          <form @submit.prevent>
            <div>
              <div class="input_text">
                <label>출고일</label>
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  :value="newData.output_date"
                  @change="setOutputDate($event)"
                  :readonly="!isPermissionOn"
                />
              </div>
            </div>
            <div>
              <div class="input_text">
                <label>제품명</label>
                <input
                  type="text"
                  id="rawMaterial"
                  readonly
                  placeholder=""
                  :value="findInfoFromId(products, newData.product_id).name"
                />
              </div>
              <div class="input_text">
                <label>매출처명</label>
                <input
                  type="text"
                  id="purchase"
                  readonly
                  placeholder=""
                  :value="
                    findInfoFromId(
                      companys,
                      findInfoFromId(sales, newData.sales_id).company_id,
                    ).name
                  "
                />
              </div>
            </div>
            <div>
              <div class="input_text">
                <label>규격</label>
                <input
                  type="text"
                  readonly
                  placeholder=""
                  :value="findInfoFromId(products, newData.product_id).standard"
                />
              </div>
              <div class="input_text">
                <label>과세여부</label>
                <input
                  type="text"
                  readonly
                  placeholder=""
                  :value="
                    findInfoFromId(products, newData.product_id).tax
                      ? '과세'
                      : '면세'
                  "
                />
              </div>
            </div>
            <div>
              <div class="input_text">
                <label>주문수량</label>
                <input
                  type="text"
                  inputmode="decimal"
                  :disabled="!isPermissionOn"
                  id="quantity"
                  placeholder=""
                  :value="$makeComma(newData.quantity)"
                  @input="
                    $inputNumber($event, newData, 'quantity');
                    newData.collect_value = isNaN(
                      calSupplyTaxGetter.total - $makeNumber(newData.discount),
                    )
                      ? 0
                      : calSupplyTaxGetter.total -
                        $makeNumber(newData.discount);
                  "
                />
              </div>
              <div class="input_text">
                <label>주문박스</label>
                <my-local-selectric
                  :id="`store_selectric`"
                  :watch="newData.box_unit_id"
                  :options="box_options"
                  :state="
                    selectedIndex == -1 || !isPermissionOn ? 'disabled' : ''
                  "
                  @changeValue="
                    $event => {
                      $store.commit('setNewBoxUnitIdToOutput', $event);
                      if (isGroupProduct) {
                        const findParentProduct = newOutputData.find(
                          x => x.product_id == newData.product_id,
                        );
                        findParentProduct.box_unit_id = $event;
                        replaceOutputChildProductQuantity(findParentProduct);
                      }
                      newData.collect_value = isNaN(
                        calSupplyTaxGetter.total -
                          $makeNumber(newData.discount),
                      )
                        ? 0
                        : calSupplyTaxGetter.total -
                          $makeNumber(newData.discount);
                    }
                  "
                  :key="reRend"
                  @refresh="changeKey($event)"
                >
                </my-local-selectric>
              </div>
            </div>
            <div>
              <div class="input_text">
                <label>단위</label>
                <input
                  type="text"
                  id="ea"
                  readonly
                  placeholder=""
                  :value="findInfoFromId(units, newData.unit_id).name"
                />
              </div>
              <div class="input_text">
                <label>총 수량</label>
                <input
                  type="text"
                  id="total_quantity"
                  placeholder=""
                  readonly
                  :value="$makeComma(calTotalQuantity())"
                />
              </div>
            </div>
            <div>
              <div class="input_text">
                <label>단가</label>
                <input
                  type="text"
                  id="unitPrice"
                  placeholder=""
                  inputmode="decimal"
                  :disabled="!isPermissionOn"
                  :value="$makeComma(newData.cost)"
                  @input="
                    $inputNumber($event, newData, 'cost');
                    newData.collect_value = isNaN(
                      calSupplyTaxGetter.total - $makeNumber(newData.discount),
                    )
                      ? 0
                      : calSupplyTaxGetter.total -
                        $makeNumber(newData.discount);
                  "
                />
              </div>
            </div>
            <div>
              <div class="input_text">
                <label>공급가액</label>
                <input
                  type="text"
                  id="supplyValue"
                  placeholder=""
                  readonly
                  :value="`₩ ${makeComma(calSupplyTaxGetter.supply)}`"
                />
              </div>
              <div class="input_text">
                <label>부가세액</label>
                <input
                  type="text"
                  id="taxAmount"
                  placeholder=""
                  readonly
                  :value="`₩ ${makeComma(calSupplyTaxGetter.tax)}`"
                />
              </div>
            </div>
            <div>
              <div class="input_text">
                <label>할인액</label>
                <input
                  type="text"
                  placeholder=""
                  :disabled="!isPermissionOn"
                  :value="$makeComma(newData.discount)"
                  @input="
                    $inputNumber($event, newData, 'discount');
                    newData.collect_value = isNaN(
                      calSupplyTaxGetter.total - $makeNumber(newData.discount),
                    )
                      ? 0
                      : calSupplyTaxGetter.total -
                        $makeNumber(newData.discount);
                  "
                />
              </div>
              <div class="input_text">
                <label>매출액</label>
                <input
                  type="text"
                  :value="
                    $makeComma(
                      isNaN(
                        calSupplyTaxGetter.total -
                          $makeNumber(newData.discount),
                      )
                        ? '0'
                        : '' +
                            makeComma(
                              calSupplyTaxGetter.total -
                                $makeNumber(newData.discount),
                            ),
                    )
                  "
                  :disabled="!isPermissionOn"
                  inputmode="decimal"
                  @blur="
                    $event => {
                      $event.target.value = isNaN(
                        calSupplyTaxGetter.total -
                          $makeNumber(newData.discount),
                      )
                        ? '0'
                        : '' +
                          makeComma(
                            calSupplyTaxGetter.total -
                              $makeNumber(newData.discount),
                          );
                    }
                  "
                  @keydown.enter.prevent="
                    $event => reCalCost(newData, $event.target.value)
                  "
                  @keypress="onlyNumber($event)"
                  @input="$inputNumberIntNoTarget($event)"
                />
              </div>
            </div>
            <div>
              <div class="input_text">
                <label>수금액</label>
                <my-selectric
                  :id="`collection_type_selectric`"
                  :watch="searchType"
                  :options="collection_options"
                  :state="
                    selectedIndex == -1 || !isPermissionOn ? 'disabled' : ''
                  "
                  :commit="'setNewDataCollectionTypeIdToOutput'"
                >
                </my-selectric>
                <input
                  v-if="newData.collection_type_id != null"
                  type="text"
                  placeholder=""
                  :value="$makeComma(newData.collect_value)"
                  @input="$inputNumber($event, newData, 'collect_value')"
                />
              </div>
            </div>
            <div class="input_text toggle_wrap">
              <label>출고 지시</label>
              <button
                class="btn_toggle"
                :class="{ off: !newData.request_out }"
                @click="
                  newData.request_out = !newData.request_out;
                  toggleRequestOut(newData);
                "
                v-show="newData.id != undefined"
              >
                <i class="fa fa-check"></i>
                <i class="fa fa-times"></i>
              </button>
            </div>
            <div class="table_wrap">
              <div class="table_title">
                <span>출고내역</span>
                <button class="btn_sub1" @click="makeOutputData(1)">
                  {{ '선입선출' }}
                </button>
              </div>
              <div class="mes_tbl_wrap out_list">
                <table
                  class="mes_tbl"
                  :class="{ group_product: isGroupProduct }"
                >
                  <colgroup>
                    <col
                      v-for="(n, index) in isGroupProduct ? 10 : 9"
                      :key="index"
                    />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        제품명{{ systemCompany.expire_yn ? ` (유통기한)` : '' }}
                      </th>
                      <th>규격</th>
                      <th>납품처</th>
                      <th>제품 식별표</th>
                      <th>현재고</th>
                      <th>총 주문수량</th>
                      <th v-show="isGroupProduct">
                        세트 투입수량
                      </th>
                      <th>수량</th>
                      <!-- <th>박스</th> -->
                      <th>총 출고수량</th>
                      <th>삭제</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(output, index) in newOutputData"
                      :key="output.id"
                    >
                      <td
                        class="text_left"
                        :alt="
                          findInfoFromId(products, output.product_id).name +
                            (output.id > 0 && systemCompany.expire_yn
                              ? ' (' + getExpireDate(output) + ' 까지)'
                              : '')
                        "
                      >
                        <div>
                          {{ findInfoFromId(products, output.product_id).name }}
                          <br
                            v-show="output.id > 0 && systemCompany.expire_yn"
                          />
                          {{
                            output.id > 0 && systemCompany.expire_yn
                              ? ` (${getExpireDate(output)}까지)`
                              : ''
                          }}
                        </div>
                      </td>
                      <td class="text_left">
                        {{
                          findInfoFromId(products, output.product_id).standard
                        }}
                      </td>
                      <td class="text_left">
                        {{
                          findInfoFromId(lots, output.lot_id).company_id == null
                            ? output.curr_stock == null
                              ? '-'
                              : '자체생산'
                            : findInfoFromId(
                                companys,
                                findInfoFromId(lots, output.lot_id).company_id,
                              ).name
                        }}
                      </td>
                      <td>
                        {{
                          output.curr_stock == null
                            ? '-'
                            : `P${makeProductBarcode(output)}`
                        }}
                      </td>
                      <!-- <td>{{ `L${output.product_ident}` }}</td> -->
                      <!-- 현재고 -->
                      <td
                        class="text_right"
                        :class="{
                          danger:
                            output.curr_stock != null &&
                            $decimalAdd(
                              output['set_quantity'] != null
                                ? $makeNumber(output.set_quantity)
                                : 0,
                              $decimalMul(
                                $makeNumber(output.quantity),
                                getBoxQuantity(output.box_unit_id),
                              ),
                            ) > output.curr_stock,
                        }"
                      >
                        <b>{{
                          output.curr_stock == null
                            ? '-'
                            : `${$makeComma(output.curr_stock)}`
                        }}</b>
                        {{
                          output.curr_stock == null
                            ? ''
                            : findInfoFromId(units, output.unit_id).name
                        }}
                      </td>
                      <!-- 총 주문수량 -->
                      <td
                        class="text_right"
                        :class="{
                          danger:
                            outputByProductId.find(
                              x =>
                                $makeNumber(x.product_id) == output.product_id,
                            ).sum !=
                            salesByProductId.find(
                              x =>
                                $makeNumber(x.product_id) == output.product_id,
                            ).sum,
                        }"
                      >
                        <b>{{
                          `${$makeComma(
                            outputByProductId.find(
                              x =>
                                $makeNumber(x.product_id) == output.product_id,
                            ).sum,
                          )} / `
                        }}</b>
                        <b>{{
                          newOutputData.length > 0
                            ? `${$makeComma(
                                salesByProductId.find(
                                  x => x.product_id == output.product_id,
                                ).sum,
                              )}`
                            : ''
                        }}</b>
                        {{ findInfoFromId(units, output.unit_id).name }}
                      </td>
                      <td class="text_right" v-show="isGroupProduct">
                        <b>{{
                          output.set_quantity == null
                            ? '-'
                            : $makeComma(output.set_quantity)
                        }}</b>
                        {{
                          output.set_quantity == null
                            ? ''
                            : findInfoFromId(units, output.unit_id).name
                        }}
                      </td>
                      <td>
                        <input
                          type="text"
                          :value="$makeComma(output.quantity)"
                          inputmode="decimal"
                          :disabled="!isPermissionOn"
                          @input="
                            $inputNumber($event, output, 'quantity');
                            if (output.curr_stock == null) {
                              replaceOutputChildProductQuantity(output);
                            }
                          "
                        />
                      </td>
                      <!-- <td>
                        <my-local-selectric
                          :id="`output_box_unit_selectric${index}`"
                          :watch="output.box_unit_id"
                          :options="box_options"
                          @changeValue="
                            output.box_unit_id = $event;
                            if (output.curr_stock == null) {
                              replaceOutputChildProductQuantity(output);
                            }
                          "
                          :class="
                            isGroupProduct && output.curr_stock != null
                              ? 'disabled'
                              : ''
                          "
                        ></my-local-selectric>
                      </td> -->
                      <td
                        class="text_right"
                        :class="{
                          danger:
                            output.curr_stock != null &&
                            $decimalAdd(
                              output['set_quantity'] != null
                                ? $makeNumber(output.set_quantity)
                                : 0,
                              $decimalMul(
                                $makeNumber(output.quantity),
                                getBoxQuantity(output.box_unit_id),
                              ),
                            ) > output.curr_stock,
                        }"
                      >
                        <b>{{
                          output.curr_stock != null
                            ? $makeComma(
                                $decimalAdd(
                                  output['set_quantity'] != null
                                    ? output.set_quantity
                                    : 0,
                                  $decimalMul(
                                    $makeNumber(output.quantity),
                                    getBoxQuantity(output.box_unit_id),
                                  ),
                                ),
                              )
                            : $makeComma(output.quantity)
                        }}</b>
                        {{
                          output.curr_stock != null
                            ? findInfoFromId(units, output.unit_id).name
                            : output.box_unit_id == null
                            ? '낱개'
                            : findInfoFromId(boxs, output.box_unit_id).name
                        }}
                      </td>
                      <td>
                        <button
                          v-show="output.curr_stock != null"
                          class="tbl_delete_btn"
                          @click="deleteOutputProduct(index)"
                        >
                          delete
                        </button>
                      </td>
                    </tr>
                    <tr class="newBtn new">
                      <td class="text_left" :colspan="isGroupProduct ? 10 : 9">
                        <button
                          @click="showProductControlSearch = true"
                          class="btn_tbl"
                        >
                          {{ '출고제품 선택' }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="btn_wrap">
              <div>
                <button
                  class="btn_sub2"
                  @click="checkValid"
                  v-show="newData.id != undefined"
                  v-if="selectedIndex != -1 && isPermissionOn"
                >
                  출고 처리
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </transition>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="my_isModalOpen = false"
      @OnYesClick="
        $event => {
          my_isModalOpen = false;
          submitForm($event);
        }
      "
    ></two-button-modal>
    <product-control-search
      v-if="showProductControlSearch"
      :list="filteredProductRemainControl"
      @onclose="showProductControlSearch = false"
      @onselect="appendOutputProduct($event)"
    ></product-control-search>
  </div>
</template>

<script>
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import PriceMixin from '@/mixins/price';
import FavoriteMixin from '@/mixins/favorite';
import MySelectric from '@/layouts/components/MySelectric.vue';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric.vue';
import ProductControlSearch from '@/layouts/components/search-popup/ProductControlSearch';
import { mapGetters } from 'vuex';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import { getExpireDate, yyyymmdd, groupArrayObject } from '@/utils/func';

export default {
  mixins: [FetchMixin, ModalMixin, SpinnerMixin, PriceMixin, FavoriteMixin],
  components: {
    MySelectric,
    ProductControlSearch,
    TwoButtonModal,
    MyLocalSelectric,
  },
  data() {
    return {
      tooltip: false,
      reRend: 1,
      reRend2: 1,
      show_store_selectric: false,
      showProductControlSearch: false,

      //delete modal
      my_modal_title: '경고',
      my_modal_content:
        '수주내역과 출고 제품 구성 및 수량이<br />일치하지 않습니다.<br /><br />그래도 출고처리 하시겠습니까?',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      systemCompany: 'getSystemCompany',
      box_options: 'getBoxForSelectric',
      companys: 'getCompany',
      products: 'getProduct',
      units: 'getUnitCodes',
      boxs: 'getBoxsNotSorted',
      sales_products: 'getManagementDataSalesProductsFromOutput',
      sales: 'getSalesFromOutput',
      lots: 'getLot',
      lot_sales: 'getLotSalesFromOutput',
      notNullProductControl: 'getNotNullProductControl',
      ProductRemainControl: 'getProductRemainControl',
      newData: 'getNewDataFromOutput',
      selectedIndex: 'getNewSelectedIndexFromOutput',
      newOutputData: 'getNewOutputData',
      collection_options: 'getCollectionTypeForSelectricFromSalesBase',
      searchType: 'getNewDataCollectionTypeIdFromOutput',
      materials: 'getMaterial',
      materialTotalStock: 'getMaterialTotalStock',
    }),
    OutputBoxSumQuantity() {
      const cloneNewData = this.lodash.clonedeep(this.newData);
      let package_material_usage_list = [];
      if (cloneNewData.box_unit_id != null) {
        const box_unit = this.lodash
          .clonedeep(this.boxs)
          .find(y => y.id == cloneNewData.box_unit_id);

        box_unit.r_box_unit_material_list.map(y => {
          const cal_usage = this.$decimalMul(y.quantity, cloneNewData.quantity);
          if (package_material_usage_list.length > 0) {
            const foundMaterial = package_material_usage_list.find(
              z => z.material_id == y.material_id,
            );
            if (foundMaterial != undefined) {
              foundMaterial.usage_quantity = this.$decimalAdd(
                foundMaterial.usage_quantity,
                cal_usage,
              );
            } else {
              package_material_usage_list.push({
                material_id: y.material_id,
                usage_quantity: cal_usage,
              });
            }
          } else {
            package_material_usage_list.push({
              material_id: y.material_id,
              usage_quantity: cal_usage,
            });
          }
        });
      }
      return package_material_usage_list;
    },
    filteredProductRemainControl() {
      let productId = [];
      if (
        this.getProductInfo(this.newData.product_id).product_group_yn &&
        this.getProductInfo(this.newData.product_id).product_group_type_id == 2
      ) {
        const findProductId = this.findInfoFromId(
          this.sales,
          this.newData.sales_id,
        )
          .product_list.filter(
            x => x.parent_product_id == this.newData.product_id,
          )
          .map(x => x.product_id);

        productId = findProductId;
      } else {
        productId.push(this.newData.product_id);
      }

      if (
        this.lot_sales != undefined &&
        this.lot_sales != null &&
        this.lot_sales.length > 0
      ) {
        let list_ = this.lot_sales.map(x => x.id);
        let lotProduct = this.lodash.clonedeep(
          this.notNullProductControl.filter(x => list_.includes(x.lot_id)),
        );
        lotProduct = lotProduct.map(x => {
          x.company_label = this.findInfoFromId(
            this.companys,
            this.newData.company_id,
          ).name;
          return x;
        });

        return this.ProductRemainControl.concat(lotProduct)
          .filter(
            x =>
              !this.newOutputData.map(y => y.id).includes(x.id) &&
              productId.includes(x.product_id),
          )
          .map(x => {
            x.create_time = this.notNullProductControl.find(
              y => y.id == x.id,
            ).create_time;
            return x;
          });
      } else {
        return this.ProductRemainControl.filter(
          x =>
            !this.newOutputData.map(y => y.id).includes(x.id) &&
            productId.includes(x.product_id),
        ).map(x => {
          x.create_time = this.notNullProductControl.find(
            y => y.id == x.id,
          ).create_time;
          return x;
        });
      }
    },
    isOutputItemNull() {
      if (
        this.newOutputData == undefined ||
        this.newOutputData == null ||
        this.newOutputData.filter(x => x.curr_stock != null).length < 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    isOutputQuantityMinus() {
      if (!this.isOutputItemNull) {
        const find = this.newOutputData.find(
          x =>
            this.$decimalAdd(
              x['set_quantity'] != null ? this.$makeNumber(x.set_quantity) : 0,
              this.calTotalOutputQuantity(x),
            ) <= 0,
        );
        if (find != undefined) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    isOutputQuantityOverThenStock() {
      if (!this.isOutputItemNull) {
        const find = this.newOutputData
          .filter(x => x.curr_stock != null)
          .find(
            x =>
              this.$decimalAdd(
                x['set_quantity'] != null
                  ? this.$makeNumber(x.set_quantity)
                  : 0,
                this.calTotalOutputQuantity(x),
              ) > this.$makeNumber(x.curr_stock),
          );
        if (find != undefined) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    isMatchedSalesNOutput() {
      if (!this.isOutputItemNull) {
        let a = this.salesByProductId;
        let b = this.outputByProductId;

        if (JSON.stringify(a) == JSON.stringify(b)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isOutputBoxQuantityOverThenStock() {
      let usage_list = this.lodash.clonedeep(this.OutputBoxSumQuantity);
      if (usage_list.length < 1) {
        return { state: false, over_list: [] };
      } else {
        usage_list = usage_list.filter(x => {
          const foundMaterial = this.materialTotalStock.find(
            y => y.material_id == x.material_id,
          );
          if (
            foundMaterial == undefined ||
            foundMaterial.quantity < x.usage_quantity
          ) {
            x.usage_quantity = this.$decimalSub(
              x.usage_quantity,
              foundMaterial ? foundMaterial.quantity : 0,
            );
            return true;
          } else {
            return false;
          }
        });
        return { state: usage_list.length > 0, over_list: usage_list };
      }
    },
    isValidData() {
      if (
        !this.isOutputItemNull &&
        !this.isOutputQuantityMinus &&
        !this.isOutputQuantityOverThenStock &&
        !this.isOutputBoxQuantityOverThenStock.state
      ) {
        return true;
      } else {
        return false;
      }
    },
    sales_product_list() {
      try {
        return this.sales
          .filter(x => x.sales_type_id != 2)
          .map(x => x.product_list.filter(y => y.parent_product_id == null))
          .reduce((a, b) => a.concat(b))
          .sort((a, b) => b.id - a.id)
          .filter(x => x.output_yn == false);
      } catch (error) {
        return [];
      }
    },
    calSupplyTaxGetter() {
      if (this.newData != null) {
        try {
          const vat = this.findInfoFromId(
            this.companys,
            this.findInfoFromId(this.sales, this.newData.sales_id).company_id,
          ).vat;
          const tax = this.findInfoFromId(
            this.products,
            this.newData.product_id,
          ).tax;
          const num = this.calTotalQuantity();
          const cos = this.$makeNumber(this.newData.cost);
          if (vat == undefined || tax == undefined) {
            return { supply: 0, tax: 0, total: 0 };
          }
          const quantity = this.$makeNumber(num);
          const cost = this.$makeNumber(cos);
          if (tax == true && vat != 3) {
            if (vat == 1) {
              const supply = Math.round(quantity * cost);
              return {
                supply: supply,
                tax: Math.floor(supply * 0.1),
                total: supply + Math.floor(supply * 0.1),
              };
            } else if (vat == 2) {
              const total = Math.round(quantity * cost);
              return {
                supply: Math.round(total / 1.1),
                tax: total - Math.round(total / 1.1),
                total: total,
              };
            } else {
              return { supply: 0, tax: 0, total: 0 };
            }
          } else if (tax == false || vat == 3) {
            return {
              supply: Math.round(quantity * cost),
              tax: 0,
              total: Math.round(quantity * cost),
            };
          } else {
            return { supply: 0, tax: 0, total: 0 };
          }
        } catch (e) {
          console.log(e);
          return { supply: 0, tax: 0, total: 0 };
        }
      } else return { supply: 0, tax: 0, total: 0 };
    },
    checkCostValid() {
      let chkCostList = [];
      const calPrice = this.calSupplyTaxGetter.supply;

      if (calPrice >= 2147483647) {
        chkCostList.push('합계액');
      }
      if (this.$makeNumber(this.newData.collect_value) >= 2147483647) {
        chkCostList.push('수금액');
      }

      return chkCostList;
    },
    chkCollectionType() {
      if (this.newData.collect_value > 0) {
        if (this.searchType == null) {
          return false;
        } else return true;
      } else return true;
    },
    getMemberProductQuantity() {
      // 자식제품 출고수량
      const productInfo = this.getProductInfo(this.newData.product_id);
      if (
        productInfo.product_group_yn &&
        productInfo.product_group_type_id == 2
      ) {
        let setProduct = this.lodash.clonedeep(productInfo.product_group_list);
        const outputParentProdcut = this.outputByProductId.find(
          x => this.$makeNumber(x.product_id) == this.newData.product_id,
        );
        setProduct.map(el => {
          el.sum = this.$makeNumber(
            this.$decimalMul(el.quantity, outputParentProdcut.sum),
          );
        });
        return setProduct;
      } else return [];
    },
    isGroupProduct() {
      if (this.newData != null) {
        return this.getProductInfo(this.newData.product_id).product_group_yn;
      } else {
        return false;
      }
    },
    outputByProductId() {
      const arr = this.lodash.clonedeep(
        groupArrayObject(this.newOutputData, 'product_id'),
      );

      let list = [];
      arr.forEach(el => {
        const mapQuantity = el.element.map(x =>
          this.$decimalAdd(
            x['set_quantity'] != null ? this.$makeNumber(x.set_quantity) : 0,
            this.calTotalOutputQuantity(x),
          ),
        );
        const sumValue = mapQuantity.reduce(
          (a, b) => this.$makeNumber(a) + this.$makeNumber(b),
        );
        list.push({
          product_id: this.$makeNumber(el.group_key),
          sum: this.$makeNumber(sumValue),
        });
      });
      return list.sort((a, b) => a.product_id - b.product_id);
    },
    salesByProductId() {
      const groupProductChk = this.getProductInfo(this.newData.product_id);
      let list = [
        {
          product_id: this.newData.product_id,
          sum: this.calTotalQuantity(),
        },
      ];
      if (groupProductChk.product_group_yn) {
        const childProducts = this.lodash.clonedeep(
          this.getProductInfo(this.newData.product_id).product_group_list,
        );

        childProducts.forEach(el => {
          list.push({
            product_id: this.$makeNumber(el.member_product_id),
            sum: this.$decimalMul(el.quantity, this.calTotalQuantity()),
          });
        });
        return list.sort((a, b) => a.product_id - b.product_id);
      } else {
        return list;
      }
    },
  },
  methods: {
    setOutputDate(e) {
      console.log('chk', e);
      this.newData.output_date = e.target.value;
    },
    getBoxQuantity(id) {
      let hit = this.boxs.find(x => x.id == id);
      return hit != undefined ? hit.quantity : 1;
    },
    getProductInfo(id) {
      let hit = this.products.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    reCalCost(row, newSalesValue) {
      const total_value =
        this.$makeNumber(newSalesValue) + this.$makeNumber(row.discount);
      console.log('total_value', total_value);
      const company_vat = this.findInfoFromId(
        this.companys,
        this.findInfoFromId(this.sales, this.newData.sales_id).company_id,
      ).vat;
      const product_tax = row.tax_id;
      let rowQuantity = this.$decimalMul(
        this.$makeNumber(row.quantity),
        row.box_unit_id != null ? this.getBoxQuantity(row.box_unit_id) : 1,
      );
      if (rowQuantity === 0) {
        row.quantity = 1;
        rowQuantity = 1;
      }

      if (company_vat != 1 || product_tax == 2) {
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(total_value, this.$makeNumber(rowQuantity)).toFixed(
            0,
          ),
        );
      } else {
        const new_total_value = this.$makeNumber(
          this.$decimalDiv(total_value, 1.1).toFixed(0),
        );
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(
            new_total_value,
            this.$makeNumber(rowQuantity),
          ).toFixed(0),
        );
      }
      row.collect_value = isNaN(
        this.calSupplyTaxGetter.total - this.$makeNumber(this.newData.discount),
      )
        ? 0
        : this.calSupplyTaxGetter.total -
          this.$makeNumber(this.newData.discount);
    },
    getExpireDate(item) {
      const products = this.findInfoFromId(this.products, item.product_id);
      return yyyymmdd(
        new Date(
          getExpireDate(
            new Date(item.create_time),
            products.expire_year,
            products.expire_month,
            products.expire_day,
          ),
        ),
      );
    },
    async toggleRequestOut(sales_product) {
      console.log(sales_product);
      await this.UPDATE_WITH_PAYLOAD(
        'UPDATE_SALES_PRODUCT_REQUEST_OUT',
        sales_product,
        '출고지시',
      );
      await this.FETCH_SALES_TO_OUTPUT();
    },
    appendOutputProduct(e) {
      const arg = this.lodash.clonedeep(e);
      arg.curr_stock = this.lodash.clonedeep(arg.quantity);
      arg.quantity = 0;
      arg.box_unit_id = null;
      arg.set_quantity = null;
      this.newOutputData.push(arg);
      if (this.isGroupProduct) {
        this.replaceOutputChildProductQuantity(arg);
      }
      this.showProductControlSearch = false;
    },
    deleteOutputProduct(index) {
      const deleteProduct = this.lodash.clonedeep(this.newOutputData[index]);
      this.newOutputData.splice(index, 1);
      if (this.isGroupProduct) {
        this.replaceOutputChildProductQuantity(deleteProduct);
      }
    },
    changeKey() {
      this.reRend += 1;
    },
    calTotalOutputQuantity(output) {
      return this.$decimalMul(
        this.$makeNumber(output.quantity),
        this.getBoxQuantity(output.box_unit_id),
      );
    },
    calTotalQuantity() {
      const total_quantity =
        this.newData.box_unit_id != null
          ? this.$makeNumber(this.newData.quantity) *
            this.findInfoFromId(this.boxs, this.newData.box_unit_id).quantity
          : this.$makeNumber(this.newData.quantity);
      return isNaN(total_quantity) ? '' : total_quantity;
    },
    makeComma(value) {
      if (value == undefined) return '';
      if (value.length == 0) {
        return '0';
      }
      value = this.$makeNumber(value);
      if (value == 0) {
        return '0';
      }
      return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    async checkValid() {
      if (this.isValidData) {
        if (this.isMatchedSalesNOutput) {
          this.submitForm();
        } else {
          this.my_isModalOpen = true;
        }
      } else {
        if (this.isOutputItemNull) {
          this.openOneButtonModal(
            '등록불가',
            '최소 하나이상의 출고 제품을 등록하여주십시오.',
          );
        } else if (this.isOutputQuantityMinus) {
          this.openOneButtonModal(
            '등록불가',
            '출고 수량은 0이거나 음수일 수 없습니다.',
          );
        } else if (this.isOutputQuantityOverThenStock) {
          this.openOneButtonModal(
            '등록불가',
            '출고 수량은 현재고량보다 클 수 없습니다.',
          );
        } else if (this.isOutputBoxQuantityOverThenStock.state) {
          this.openOneButtonModal(
            '등록불가',
            `출고에 사용될 박스의 포장재 재고가 부족합니다.<br/>
          ====================================<br/>
          ${this.isOutputBoxQuantityOverThenStock.over_list
            .map(
              x =>
                this.findInfoFromId(this.materials, x.material_id).name +
                ' > ' +
                this.$makeComma(x.usage_quantity) +
                ' ' +
                this.findInfoFromId(
                  this.units,
                  this.findInfoFromId(this.materials, x.material_id)
                    .incoming_unit_id,
                ).name +
                ' 부족',
            )
            .join('<br/>')}
          
          `,
          );
        }
      }
    },
    async submitForm() {
      if (this.checkCostValid.length == 0) {
        const payload = this.lodash.clonedeep(this.newData);
        // console.log(payload);
        let SupplyMoney = 0;
        let TaxMoney = 0;
        let TotalMoney = 0;
        let DiscountMoney = 0;
        let SalesMoney = 0;
        let TaxProductMony = 0;
        let TaxFreeProductMony = 0;
        payload.input_date = yyyymmdd(new Date());
        payload.quantity = this.$makeNumber(payload.quantity);
        payload.collect_value =
          payload.collection_type_id != null
            ? this.$makeNumber(payload.collect_value)
            : 0;
        payload.cost = this.$makeNumber(payload.cost);
        payload.discount = this.$makeNumber(payload.discount);
        payload.tax_id = this.findInfoFromId(
          this.products,
          payload.product_id,
        ).tax;
        payload.supply_value = this.calSupplyTaxGetter.supply;
        payload.tax = this.calSupplyTaxGetter.tax;
        payload.total_cost = this.calSupplyTaxGetter.total;
        SupplyMoney += payload.supply_value;
        TaxMoney += payload.tax;
        TotalMoney += payload.total_cost;
        DiscountMoney += payload.discount;
        SalesMoney += payload.total_cost - payload.discount;
        if (payload.tax_id == true) {
          TaxProductMony += payload.total_cost;
        } else {
          TaxFreeProductMony += payload.total_cost;
        }

        //전표만들기
        const account = {
          output_date: payload.output_date,
          supply_value: SupplyMoney,
          tax: TaxMoney,
          total_value: TotalMoney,
          sales_value: SalesMoney,
          tax_value: TaxProductMony,
          tax_free_value: TaxFreeProductMony,
          discount: DiscountMoney,
          sales_id: this.newData.sales_id,
          detail: null,
        };

        let productOutPayload = this.lodash
          .clonedeep(this.newOutputData)
          .filter(x => x.curr_stock != null);
        productOutPayload.forEach(x => {
          x.sales_product_id = payload.id;
        });
        productOutPayload.forEach(el => {
          el.quantity = this.$decimalAdd(
            el['set_quantity'] != null ? this.$makeNumber(el.set_quantity) : 0,
            this.calTotalOutputQuantity(el),
          );
        });
        if (this.isGroupProduct) {
          const findChildProductId = this.lodash
            .clonedeep(
              this.sales.find(x => x.id == this.newData.sales_id).product_list,
            )
            .filter(x => x.parent_product_id == this.newData.product_id)
            .map(x => x.id);

          payload.child_product_id = findChildProductId;
        }
        payload.account = account;
        payload.product_out_list = productOutPayload;

        console.log('payload', payload);
        payload.product_out_list.forEach(el => {
          el.quantity = this.calTotalOutputQuantity(el);
        });
        payload.box_out_list = this.lodash.clonedeep(this.OutputBoxSumQuantity);

        this.showSpinner();
        this.$store
          .dispatch('UPDATE_SALES_ONE_ELEMENT_COMPLETE', payload)
          .then(async () => {
            this.openOneButtonModal(
              '출고 성공',
              '성공적으로 출고처리하였습니다.',
            );
            await this.FETCH_SALES_TO_OUTPUT();
            await this.FETCH('FETCH_NOT_NULL_PRODUCT_CONTROL');
            await this.FETCH('FETCH_PRODUCT_REMAIN_CONTROL');
            this.selectRow(-1);
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '수정 실패',
              '수주 수정 중 오류가 발생했습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else if (!this.chkCollectionType) {
        this.openOneButtonModal(
          `수금구분 선택 오류`,
          '수금구분을 선택해주세요.',
        );
        return;
      } else {
        this.openOneButtonModal(
          `${this.checkCostValid} 초과`,
          '1회 출고 최대 금액은<br />20억 이상 출고할 수 없습니다.',
        );
        return;
      }
    },
    async FETCH_SALES_TO_OUTPUT() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_SALES_TO_OUTPUT', {
          start_date: '1700-01-01',
          end_date: '2500-01-01',
          check_only_not_completed: true,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },

    async selectRow(index) {
      this.$store.commit('setNewSelectedIndexToOutput', index);
      if (index != -1) {
        let salesList = this.lodash.clonedeep(this.sales_product_list[index]);
        salesList.collect_value = salesList.total_cost;
        salesList.child_product_id = [];
        const today = new Date();
        salesList.output_date = yyyymmdd(today);

        if (salesList.collection_type_id == undefined) {
          salesList.collection_type_id = null;
        }
        await this.$store.commit('setNewDataToOutput', salesList);
        await this.FETCH_LOT_SALES(this.newData.sales_id);

        if (this.isGroupProduct) {
          this.makeGroupProduct(salesList);
        }
        this.makeOutputData(0);
        this.show_store_selectric = true;
      } else {
        this.$store.commit('setNewDataToOutput', null);
        this.$store.commit('setNewOutputData', []);
      }
    },
    makeGroupProduct(groupSalesInfo) {
      const findCompanyId = this.findInfoFromId(
        this.sales,
        this.newData.sales_id,
      ).company_id;

      console.log('groupSalesInfo', groupSalesInfo);
      console.log('findCompanyId', findCompanyId);

      this.newOutputData.push({
        box_unit_id: groupSalesInfo.box_unit_id,
        create_time: null,
        curr_stock: null,
        id: -1,
        lot_company_id: findCompanyId,
        lot_id: null,
        product_id: groupSalesInfo.product_id,
        product_ident: null,
        quantity: groupSalesInfo.quantity,
        sales_company_id: findCompanyId,
        sales_id: groupSalesInfo.sales_id,
        store_id: null,
        unit_id: groupSalesInfo.unit_id,
      });
    },
    async FETCH_LOT_SALES(sales_id) {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_LOT_SALES_TO_OUTPUT', sales_id)
        .then(() => {})
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    makeOutputData(chk) {
      //  chk - 0:초기진입, 1:선입선출
      let list_ = this.lot_sales.map(x => x.id);
      let stockList = this.lodash.clonedeep(
        this.notNullProductControl.filter(
          x =>
            list_.includes(x.lot_id) &&
            this.salesByProductId.map(y => y.product_id).includes(x.product_id),
        ),
      );

      if (chk == 1) {
        stockList = stockList.concat(
          this.lodash.clonedeep(
            this.ProductRemainControl.filter(x =>
              this.salesByProductId
                .map(y => y.product_id)
                .includes(x.product_id),
            ),
          ),
        );
      }
      stockList = stockList.map(x => {
        x.curr_stock = x.quantity;
        x.curr_stock_temp = x.quantity;
        x.box_unit_id = null;
        x.set_quantity = null;

        return x;
      });

      stockList.sort(
        (a, b) => new Date(a.create_time) - new Date(b.create_time),
      );

      // 세트상품 채우기
      if (this.salesByProductId.length > 1) {
        this.newOutputData.splice(1);

        let getMemberProductQuantity = this.lodash.clonedeep(
          this.getMemberProductQuantity,
        );

        getMemberProductQuantity.forEach(x => {
          if (
            stockList.find(y => y.product_id == x.member_product_id) !=
            undefined
          ) {
            let filteredStockList = stockList.filter(
              y => y.product_id == x.member_product_id,
            );
            filteredStockList.forEach((y, index) => {
              if (y.curr_stock_temp > x.sum) {
                y.quantity = 0;
                y.set_quantity = x.sum;
                y.curr_stock_temp = this.$decimalSub(
                  this.$makeNumber(y.curr_stock_temp),
                  this.$makeNumber(x.sum),
                );
                x.sum = 0;
              } else {
                y.quantity = 0;
                y.set_quantity =
                  filteredStockList.length - 1 == index
                    ? x.sum
                    : y.curr_stock_temp;
                x.sum = this.$decimalSub(
                  this.$makeNumber(x.sum),
                  this.$makeNumber(y.curr_stock_temp),
                );
                y.curr_stock_temp = 0;
              }
            });
          }
        });
      } else {
        // 단품 채우기
        this.newOutputData.splice(0);
        let getSalesProductsList = this.lodash.clonedeep(this.salesByProductId);

        getSalesProductsList.forEach(x => {
          if (stockList.find(y => y.product_id == x.product_id) != undefined) {
            let filteredStockList = stockList.filter(
              y => y.product_id == x.product_id && y.curr_stock_temp > 0,
            );
            filteredStockList.forEach((y, index) => {
              if (y.curr_stock_temp > x.sum) {
                y.quantity = x.sum;
                y.curr_stock_temp = this.$decimalSub(y.curr_stock_temp, x.sum);
                x.sum = 0;
              } else {
                y.quantity =
                  filteredStockList.length - 1 == index
                    ? x.sum
                    : y.curr_stock_temp;
                x.sum = this.$decimalSub(x.sum, y.curr_stock_temp);
                y.curr_stock_temp = 0;
              }
            });
          }
        });
      }

      stockList = stockList.filter(
        x =>
          !(
            (x.set_quantity == 0 && x.quantity == 0) ||
            (x.set_quantity == null && x.quantity == 0)
          ),
      );
      console.log('stockList 확인', stockList);
      this.$store.commit(
        'setNewOutputData',
        this.newOutputData.concat(stockList),
      );
    },
    makeProductBarcode(product) {
      if (product.curr_stock != null) {
        const date = yyyymmdd(new Date(product.create_time)).replace(/-/gi, '');
        const product_control_id = product.id;
        const fix5 = ('00000' + product_control_id).substr(-5);
        product.barcode_num = date + fix5;
        return date + fix5;
      } else {
        product.barcode_num = null;
        return null;
      }
    },
    replaceOutputChildProductQuantity(output) {
      //output의 product_group_yn로 부모제품인지 자식제품인지 확인
      const groupProductChk = this.getProductInfo(output.product_id);

      //출고내역 그룹제품 수정시 수량 재계산
      let groupChildProduct = groupProductChk.product_group_list.map(
        y => y.member_product_id,
      );
      let filteredNewOutputData = groupProductChk.product_group_yn
        ? this.newOutputData.filter(x =>
            groupChildProduct.includes(x.product_id),
          )
        : this.newOutputData.filter(x => x.product_id == output.product_id);
      filteredNewOutputData = filteredNewOutputData.map(x => {
        x.curr_stock_temp = x.curr_stock;
        return x;
      });

      // 세트상품 수량 채우기
      let salesChildProductList = groupProductChk.product_group_yn
        ? this.lodash
            .clonedeep(this.getMemberProductQuantity)
            .filter(x => groupChildProduct.includes(x.member_product_id))
        : this.lodash
            .clonedeep(this.getMemberProductQuantity)
            .filter(x => x.member_product_id == output.product_id);

      salesChildProductList.forEach(x => {
        let findManageOutputData = filteredNewOutputData.filter(
          y => y.product_id == x.member_product_id,
        );
        findManageOutputData.forEach((y, index) => {
          if (y.curr_stock_temp > x.sum) {
            y.set_quantity = x.sum;
            y.curr_stock_temp = this.$decimalSub(
              this.$makeNumber(y.curr_stock_temp),
              this.$makeNumber(x.sum),
            );
            x.sum = 0;
          } else {
            y.set_quantity =
              findManageOutputData.length - 1 == index
                ? x.sum
                : y.curr_stock_temp;
            x.sum = this.$decimalSub(
              this.$makeNumber(x.sum),
              this.$makeNumber(y.curr_stock_temp),
            );
            y.curr_stock_temp = 0;
          }
        });
      });
    },
  },
  async created() {
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.box_options.length < 2) {
      await this.FETCH('FETCH_BOX', '박스');
    }
    if (this.collection_options.length < 2) {
      await this.FETCH('FETCH_COLLECTION_TYPE', '수금구분');
    }
    if (this.lots.length < 1) {
      await this.FETCH('FETCH_LOT_ALL');
    }
    await this.FETCH_SALES_TO_OUTPUT();
    await this.FETCH('FETCH_NOT_NULL_PRODUCT_CONTROL');
    await this.FETCH('FETCH_PRODUCT_REMAIN_CONTROL');
    await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');
    if (
      this.newData != null &&
      (this.sales.find(x => x.id == this.newData.sales_id) == undefined ||
        this.sales
          .find(x => x.id == this.newData.sales_id)
          .product_list.find(x => x.id == this.newData.id) == undefined)
    ) {
      this.selectRow(-1);
    }

    // this.show_store_selectric = true;
  },
};
</script>

<style>
/* 애니메이션 진입 및 진출은 다른 지속 시간 및  */
/* 타이밍 기능을 사용할 수 있습니다. */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.55s ease-in-out;
}
.slide-fade-enter {
  transform: translateX(100%);
}
.slide-fade-leave-to {
  transform: translateX(100%);
}
.danger b {
  color: #f98787;
}
</style>
